import HeaderContainer_ThreeDeeScreenComponent from '@wix/thunderbolt-elements/src/components/HeaderContainer/viewer/skinComps/ThreeDeeScreen/ThreeDeeScreen.skin';


const HeaderContainer_ThreeDeeScreen = {
  component: HeaderContainer_ThreeDeeScreenComponent
};


export const components = {
  ['HeaderContainer_ThreeDeeScreen']: HeaderContainer_ThreeDeeScreen
};


// temporary export
export const version = "1.0.0"
